export const loginFormValidation = (formData) => {
    try {
        const errorObj = {};
        // Validate login email
        const email = formData.email.trim();
        if (!email) {
            errorObj.email = "Please Enter Email Address";
        }
        else if (email.length > 100) {
            errorObj.email = "Email should be less than 100 characters long";
        }

        // Validate login password
        const password = formData.password.trim();
        if (!password) {
            errorObj.password = "Please Enter Password";
        }
        else if (password.length < 4 || password.length > 100) {
            errorObj.password = "Password should be between 4 and 100 characters long";
        }

        if (Object.keys(errorObj)?.length) {
            return { errorMessage: errorObj }
        }
        return {
            data: { email, password }
        }
    } catch (error) { }
}

export const companyFormValidation = (formData) => {
    try {
        const errorObj = {};
        // Validate company name
        const name = formData.name.trim();
        if (!name) {
            errorObj.name = "Please Enter Company Name";
        }
        else if (name.length < 3 || name.length > 100) {
            errorObj.name = "Company name should be between 3 and 100 characters long";
        }

        // Validate company license number
        const license_number = formData.license_number.trim();
        if (!license_number) {
            errorObj.license_number = "Please Enter License Number";
        }
        else if (license_number.length > 100) {
            errorObj.license_number = "License number should be less than 100 characters long";
        }
        else if (!Number(license_number)) {
            errorObj.license_number = "License number should contain only numbers";
        }

        // Validate company code
        const code = formData.code.trim();
        if (!code) {
            errorObj.code = "Please Enter Company Code";
        }
        else if (code.length > 100) {
            errorObj.code = "Code should be less than 100 characters long";
        }
        else if (!Number(code)) {
            errorObj.code = "Code should contain only numbers";
        }

        if (Object.keys(errorObj)?.length) {
            return { errorMessage: errorObj }
        }

        return {
            data: { name, license_number, code }
        }
    } catch (error) { }
}

export const customerFormValidation = (formData) => {
    try {
        const errorObj = {};
        // Validate company of customer
        const company_id = formData.company_id?.trim();
        if (!company_id) {
            errorObj.id = "Please Select Company";
        }

        // Validate customer name
        const name = formData.name.trim();
        if (!name) {
            errorObj.name = "Please Enter Customer Name";
        }
        else if (name.length < 3 || name.length > 100) {
            errorObj.name = "Name should be between 3 and 100 characters long";
        };

        // Validate customer email
        const email = formData.email.trim();
        if (!email) {
            errorObj.email = "Please Enter Customer Email";
        }
        else if (email.length > 100) {
            errorObj.email = "Email should be less than 100 characters long";
        };

        // Validate customer phone number
        const phone = formData.phone.trim();
        if (!phone) {
            errorObj.phone = "Please Enter Customer Phone Number";
        }
        else if (phone.length < 8 || phone.length > 20) {
            errorObj.phone = "Invalid phone number";
        };

        // Validate customer password
        const password = formData?.password?.trim();
        if (formData?.passwordByCustomer === false) {
            if (!password) {
                errorObj.password = "Please Enter Password";
            }
            else if (password.length < 4 || password.length > 100) {
                errorObj.password = "Password should be between 4 and 100 characters long";
            };
        }

        if (Object.keys(errorObj)?.length) {
            return { errorMessage: errorObj }
        }

        return {
            data: { company_id, name, email, phone, password }
        }

    } catch (error) { }
}

export const customerLocationFormValidation = (formData) => {
    try {
        const errorObj = {};
        // Validate location place of issue
        const place_of_issue = formData.place_of_issue.trim();
        if (!place_of_issue) {
            errorObj.place_of_issue = "Please Enter Place Of Issue";
        }
        else if (place_of_issue.length > 100) {
            errorObj.place_of_issue = "Place of issue should be less than 100 characters long";
        }

        // Validate location name
        const name = formData.name.trim();
        if (!name) {
            errorObj.name = "Please Enter Location Name";
        }
        else if (name.length < 3 || name.length > 100) {
            errorObj.name = "Location name should be between 3 and 100 characters long";
        }

        // Validate location license number
        const license_number = formData.license_number.trim();
        if (!license_number) {
            errorObj.license_number = "Please Enter License Number";
        }
        else if (license_number.length > 100) {
            errorObj.license_number = "License number should be less than 100 characters long";
        }
        else if (!Number(license_number)) {
            errorObj.license_number = "License number should contain only numbers";
        }

        // Validate location code
        const code = formData.code.trim();
        if (!code) {
            errorObj.code = "Please Enter Location Code";
        }
        else if (code.length > 100) {
            errorObj.code = "Code should be less than 100 characters long";
        }
        else if (!Number(code)) {
            errorObj.code = "Code should contain only numbers";
        }

        // Validate location address
        const address = formData.address.trim();
        if (!address) {
            errorObj.address = "Please Enter Location Address";
        }
        if (address.length > 300) {
            errorObj.address = "Address should be less than 300 characters long";
        }

        // Validate contract start date
        const contract_start_date = formData.contract_start_date;
        if (!contract_start_date) {
            errorObj.contract_start_date = "Please select contract start date";
        }

        // Validate contract duration in months
        const contract_duration = formData.contract_duration;
        if (!contract_duration) {
            errorObj.contract_duration = "Please Enter Contract Duration";
        }
        if (contract_duration) {
            if (contract_duration <= 0) {
                errorObj.contract_duration = "Contract duration should be a positive number";
            } else if (contract_duration > 180) {
                errorObj.contract_duration = "The contract duration can not be greater than 180 months";
            } else if (contract_duration.toString().includes('.')) {
                errorObj.contract_duration = "Please enter Valid Number";
            }
        }

        if (Object.keys(errorObj)?.length) {
            return { errorMessage: errorObj }
        }

        return {
            data: { place_of_issue, name, license_number, code, address, contract_duration }
        }
    } catch (error) { }
}

export const changePasswordFormValidation = (formData) => {
    try {
        const errorObj = {};
        // Validate current password
        const password = formData.password.trim();
        if (!password) {
            errorObj.password = "Please Enter Current Password";
        }

        // Validate new password
        const new_password = formData.new_password.trim();
        if (!new_password) {
            errorObj.new_password = "Please Enter New Password";
        }
        else if (new_password.length < 4 || new_password.length > 100) {
            errorObj.new_password = "Password should be between 4 and 100 characters long";
        };

        // Current password and new password should not be same
        if (password && new_password && password === new_password) {
            errorObj.new_password = "Please Enter New Password Different than Current Password";
        }

        // Validate confirm password
        const confirm_password = formData.confirm_password.trim();
        if (!confirm_password) {
            errorObj.confirm_password = "Please Enter Confirm New Password";
        }
        else if (!errorObj.new_password && new_password !== confirm_password) {
            errorObj.confirm_password = "New password and confirm password don't match";
        };

        if (Object.keys(errorObj)?.length) {
            return { errorMessage: errorObj }
        }

        return {
            data: { password, new_password, confirm_password }
        }
    } catch (error) { }
}

export const editProfileFormValidation = (formData) => {
    try {
        const errorObj = {};
        // Validate user name
        const name = formData.name.trim();
        if (!name) {
            errorObj.name = "Please Enter Name";
        }
        else if (name.length < 3 || name.length > 100) {
            errorObj.name = "Name should be between 3 and 100 characters long";
        };

        // Validate user phone number
        const phone = formData.phone.trim();
        if (!phone) {
            errorObj.phone = "Please Enter Phone Number";
        }
        else if (phone.length < 8 || phone.length > 20) {
            errorObj.phone = "Invalid phone number";
        };

        if (Object.keys(errorObj)?.length) {
            return { errorMessage: errorObj }
        }

        return {
            data: { name, phone }
        }
    } catch (error) { }
}

export const resetPasswordFormValidation = (formData) => {
    try {
        const errorObj = {};

        // Validate new password
        const new_password = formData.new_password.trim();
        if (!new_password) {
            errorObj.new_password = "Please Enter New Password";
        }
        else if (new_password.length < 4 || new_password.length > 100) {
            errorObj.new_password = "Password should be between 4 and 100 characters long";
        };

        // Validate confirm password
        const confirm_password = formData.confirm_password.trim();
        if (!confirm_password) {
            errorObj.confirm_password = "Please Enter Confirm New Password";
        }
        else if (!errorObj.new_password && new_password !== confirm_password) {
            errorObj.confirm_password = "New password and confirm password don't match";
        };

        if (Object.keys(errorObj)?.length) {
            return { errorMessage: errorObj }
        }

        return {
            data: { new_password, confirm_password }
        }
    } catch (error) { }
}

export const customerLocationMappingValidation = (rowData) => {
    let errorMessage = '';
    if (rowData?.checked) {
        if (!rowData?.contract_duration) {
            errorMessage = "Please Enter Contract Duration";
        } else if (rowData.contract_duration <= 0) {
            errorMessage = "Contract duration should be a positive number";
        } else if (rowData.contract_duration > 180) {
            errorMessage = "Duration can not be greater than 180";
        } else if (rowData.contract_duration.includes('.')) {
            errorMessage = "Please enter Valid Number";
        }
    }
    return errorMessage;
}